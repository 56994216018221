import { isset } from "@/helpers";
import { ErrorStore } from "@/store/errors";
import { UserStore } from "@/store/user";
import axios from "axios";


export const authClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const errorStore = ErrorStore();
    errorStore.$reset();
    errorStore.pushErrors(error.response.data.errors);
    return Promise.reject(error);
  }
);

/**
 * $csrf
 * 
 * Récupère le cookie csrf
 * 
 * @returns 
 */
export const $csrf = () => authClient.get("/sanctum/csrf-cookie");

/**
 * authActions
 * 
 * Toutes les actions d'authentification
 */
export const authActions = {
  async login(payload) {
    await $csrf();
    return authClient.post("/api/sanctum/login", payload);
  },
  logout() {
    const user = UserStore();
    user.clearTokensLocal();
    return authClient.post("/api/sanctum/logout");
  },
  async forgotPassword(payload) {
    await $csrf();
    return authClient.post("/api/sanctum/forgot-password", payload);
  },
  getAuthUser() {
    const user = UserStore();
    return authClient.get("/api/sanctum/auth/user", {
      headers: { "Authorization": `Bearer ${user.getAccessToken}` }
    });
  },
  async resetPassword(payload) {
    await $csrf();
    return authClient.post("/api/sanctum/reset-password", payload);
  },
  updatePassword(payload) {
    return authClient.put("/user/password", payload);
  },
  async registerUser(payload) {
    await $csrf();
    return authClient.post("/api/sanctum/register", payload);
  },
  sendVerification(payload) {
    return authClient.post("/api/sanctum/email/verification-notification", payload);
  },
  updateUser(payload) {
    return authClient.put("/user/sanctum/profile-information", payload);
  },
};

/**
 * removeElements
 * 
 * Fonction pour remove les elements (parce que el.remove() ça marche pas)
 * 
 * @param {HTMLElement} el 
 */
function removeElements(el){
  el.innerHTML = "";
  el.style.display = 'none';
}


export const authDirectives = {
  guest: {
    /**
     * Check si l'utilisateur est en mode invité (non-authentifié)
     * 
     * Exemple :
     * 
     * ```vue
     * <!-- Check si l'utilisateur est invité -->
     * <div v-guest></div>
     * ```
     * 
     * @param {HTMLElement} el 
     */
    created: function(el) {
        const user = UserStore();
        // console.log(user.hasToken('id'));
        if(user.hasToken('id')){
          // return false;
          removeElements(el);
        }
    }
  },
  auth: {
    /**
     * Check si l'utilisateur est authentifié et optionnellement check le role
     * 
     * Exemple :
     * 
     * ```vue
      * <!-- Check si l'utilisateur est authentifié -->
     * <div v-auth></div>
     * 
     * <!-- Check si l'utilisateur est authentifié et s'il a le role "salarie" -->
     * <div v-auth:salarie></div>
     * 
     * <!-- Check si l'utilisateur est authentifié et s'il a le role "entreprise" -->
     * <div v-auth:entreprise></div>
     * ```
     * 
     * @param {HTMLElement} el
     * @param {import("vue").DirectiveBinding} binding  
     */
    created: function(el, binding) {
        // console.log(binding);
        const user = UserStore();
        if(!user.hasToken('id')){
          // return false;
          removeElements(el);
        }
        if(isset(binding.arg)){
          if(!user.hasRoles(binding.arg)){
            removeElements(el);
          }
        }
    }
  },
  hasRole: {
    /**
     * Check les roles de l'utilisateur authentifié en fonction selon l'agument en paramètre
     * 
     * Exemple : 
     * 
     * ```vue
     * <!-- Si l'utilisateur a le role 'salarie' -->
     * <div v-hasRole="'salarie'"></div>
     * 
     * <!-- Si l'utilisateur l'un de ces roles : 'salarie' ou 'entreprise' -->
     * <div v-hasRole="['salarie','entreprise']"></div>
     * ```
     * 
     * @param {HTMLElement} el 
     * @param {import("vue").DirectiveBinding} binding 
     */
    created: function(el, binding) {
        const user = UserStore();
        if(!user.hasRoles(binding.value)){
          removeElements(el);
        }
    }
  },
}